import { Controller } from "stimulus"
import Sortable from "sortablejs"
import { AutoScroll } from "sortablejs/modular/sortable.core.esm.js";

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element,{
      onEnd: this.end.bind(this),
      animation: 150
    })
    Sortable.mount(new AutoScroll());
  }

  end(event){
    var data = new FormData();

    var position = event.newIndex + 1;
    var url_type = event.item.dataset.url_type;

    data.append("position", position);
    data.append("url_type",url_type);

    if (url_type == "bank") {
      var url = '/bank/update_order';
      var bank_id = event.item.dataset.bank_id;
      data.append("bank_id",bank_id);
    }else if (url_type == "platform") {
      var url = '/dashboard/glink/update_order';
      var id = event.item.id;
      data.append("id",id);
    }else if (url_type == "social_platform") {
      var url = '/social_platforms/update_order';
      var sp_id = event.item.id;
      data.append("sp_id",sp_id);
    }

    Rails.ajax({
      url: url,
	    type: 'PATCH',
	    data: data
    })
  }
}
