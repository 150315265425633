import consumer from './consumer'

console.log("importing consumer")
consumer.subscriptions.create('ReleaseGridUpdateChannel', {
  received: function(data){
    var data = JSON.parse(data);
    console.log(data);
    window.gridOptions.api.forEachNode(node => {
      if (node.data.id === data.id) {
        node.setData(data);
        window.gridOptions.api.flashCells({ rowNodes: [node] });
      }
    });
  }
});
